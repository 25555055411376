<template>
  <div>
    <div
      v-if="warehouse === null || warehouse === undefined"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("productHistory.noWarehouseSelected2") }}
    </div>
    <div
      v-else-if="selectedWarehouse?.type !== 3"
      class="m-5 p-5 text-center bold-40"
    >
      {{ $t("order.thisWarehouseNotARetailType") }}
    </div>
    <div class="row gap-3" v-else>
      <div class="col">
        <router-link to="/sales/collects" class="btn btn-secondary btn-no-r">
          <span><BaseIcon name="active" /> {{ $t("order.collectList") }}</span>
        </router-link>
        <router-link
          to="/sales/products"
          class="btn btn-no-r btn-no-l"
          :class="type !== 'return' ? 'btn-primary' : 'btn-secondary'"
        >
          <span><BaseIcon name="box" /> {{ $t("order.packingList") }}</span>
        </router-link>
        <router-link
          to="/sales/delivery-notes"
          class="btn btn-secondary btn-no-r btn-no-l"
        >
          <span
            ><BaseIcon name="delivery-info" />
            {{ $t("salesCollectList.deliveryNotes") }}</span
          >
        </router-link>
        <router-link
          to="/sales/return"
          class="btn btn-no-l"
          :class="type === 'return' ? 'btn-primary' : 'btn-secondary'"
        >
          <span
            ><BaseIcon name="truck-remove" /> {{ $t("order.returnList") }}</span
          >
        </router-link>
      </div>
      <div class="col-auto text-end">
        <button v-on:click="loadData" class="btn btn-type-2 btn-small">
          <span
            ><BaseIcon name="refresh" />
            {{ $t("salesCollectList.refresh") }}</span
          >
        </button>
      </div>
      <div class="col-12" v-if="orders === undefined">
        <div
          v-for="i in 3"
          :key="i"
          class="nature-white-box no-min-height mb-3"
        >
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>
                <Skeletor :width="Math.random() * 21 + 30 + '%'" />
              </h2>
              <div>
                <span>
                  <Skeletor :width="Math.random() * 10 + 10 + '%'" />
                </span>
              </div>
            </div>
          </div>
          <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.3"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.6"
          />
          <Skeletor
            class="p-3 my-1"
            :width="Math.random() * 30 + 70 + '%'"
            v-if="Math.random() > 0.9"
          />
        </div>
      </div>
      <div v-else class="col-12">
        <div v-if="orders.length === 0" class="text-center mt-4">
          <Vue3Lottie
            :animationData="emptyJSON"
            :height="160"
            :width="300"
            :key="key"
          />
          <div class="bold-40 m-2 p-5">
            {{ $t("salesProductList.thereIsNothingToPack") }}
          </div>
        </div>
        <div
          class="nature-white-box no-min-height mb-3"
          v-for="order in filteredOrders"
          :key="order.id"
        >
          <div class="white-box-top"></div>
          <div class="row mt-3 mb-4 ms-2">
            <div class="col">
              <h2>{{ order.first_name }} {{ order.last_name }}</h2>
              <div>
                <span>
                  <router-link
                    :to="'/sales/' + order.id"
                    class="text-start"
                    target="_blank"
                  >
                    {{ order.pretty_id }}
                  </router-link>
                </span>
                <span
                  v-if="type === 'return'"
                  class="ms-2 text-center w-20 d-inline-block"
                  >{{ order.public_tracking_code }}</span
                >
                <span v-if="type === 'return'" class="ms-2">
                  <span
                    class="badge py-0"
                    :class="deliveredStatuses[order.delivered_status].color"
                  >
                    <BaseIcon
                      :name="
                        deliveredStatuses[order.delivered_status]
                          ? deliveredStatuses[order.delivered_status].icon
                          : 'box-search'
                      "
                    />
                    {{
                      deliveredStatuses[order.delivered_status]
                        ? deliveredStatuses[order.delivered_status].name
                        : "Waiting"
                    }}
                  </span>
                </span>

                <span class="ms-1" v-if="order.comment">{{
                  $t(`salesProductList.comment`, { comment: order.comment })
                }}</span>
              </div>
            </div>
            <div
              class="col-auto custom-input whitebg-input"
              v-if="type !== 'return'"
            >
              <div class="input-group mb-1 px-0 py-2">
                <span class="input-group-text">
                  <button
                    class="btn btn-link"
                    v-on:click="
                      () => {
                        if (order.packages > 1) {
                          order.packages--;
                        }
                      }
                    "
                  >
                    <BaseIcon name="minus-circle" />
                  </button>
                  <input
                    type="number"
                    class="form-control w-4rem"
                    v-model="order.packages"
                  />
                  <button
                    class="btn btn-link"
                    v-on:click="
                      () => {
                        order.packages++;
                      }
                    "
                  >
                    <BaseIcon name="plus-circle" />
                  </button>
                </span>
              </div>
            </div>
            <div class="col-auto mt-2 me-3">
              <button
                class="btn btn-success p-2"
                data-bs-placement="top"
                :title="$t(`salesProductList.printLabel`)"
                :disabled="order.loadShipBtn"
                v-on:click="
                  printLabel(order.tracking_code, order.delivery_method_id)
                "
                :class="{ 'loading-spin': order.loadShipBtn }"
                v-if="order.tracking_code !== undefined"
              >
                <BaseIcon name="printer" />
              </button>
              <button
                class="btn btn-primary p-2"
                data-bs-placement="top"
                :title="$t(`order.createDelivery`)"
                :disabled="order.loadShipBtn"
                v-on:click="generateShipping(order)"
                :class="{ 'loading-spin': order.loadShipBtn }"
                v-else-if="type !== 'return'"
              >
                <BaseIcon name="box-add" />
              </button>
              <button
                class="btn btn-outline-danger p-2 ms-2"
                data-bs-placement="top"
                :title="$t(`salesCollectList.setCollected`)"
                :disabled="order.loadCollectBtn"
                v-on:click="setCollected(order)"
                :class="{ 'loading-spin': order.loadCollectBtn }"
              >
                <BaseIcon name="tick-circle" />
              </button>
            </div>
            <div class="col-6 custom-input whitebg-input">
              <label class="input-group mb-1">
                <span class="input-group-text me-3">
                  <BaseIcon
                    :name="order.show_success ? 'tick-circle' : 'scanner'"
                  />
                </span>
                <input
                  :placeholder="$t(`salesProductList.readBarcode`)"
                  type="text"
                  class="form-control"
                  v-model="order.barcode"
                  :disabled="order.disable"
                  :ref="
                    order.order_package_id
                      ? 'serach_box_' + order.id + '_' + order.order_package_id
                      : 'serach_box_' + order.id
                  "
                  v-on:change="
                    addToPackage(
                      order.id,
                      $event.target.value,
                      order.order_package_id
                    )
                  "
                />
              </label>
            </div>
          </div>
          <div
            class="d-flex gap-3 align-items-center mb-4"
            v-if="order.extraItems"
          >
            <h3 class="bold-16">Extra items:</h3>
            <div
              class="row col-2"
              v-for="extraItem in order.extraItems"
              :key="extraItem.id"
            >
              <div class="col-auto">
                <BaseIcon name="no-image" class="product-image" />
              </div>
              <div class="col">
                <div class="bold-14">
                  {{ extraItem.serial }}
                </div>
                <div class="regular-14">
                  {{ extraItem.sku }}
                </div>
              </div>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="order.orderItems"
            :show-del="this.type === 'return'"
            :show-add="false"
            :show-edit="false"
            v-on:delete="(d) => cancelReturnModal(order, d)"
            type="products"
          />
        </div>
      </div>
      <ScanModal
        ref="packagingModal"
        :title="packagingInfo.status"
        :text="packagingInfo.text"
        :icon="packagingInfo.icon"
        v-model:selected-items="packagingInfo.selectedItems"
        :select-item-method="packagingInfo.selectItemMethod"
      />
      <ConfirmModal
        ref="confirmModal"
        :title="$t(`salesProductList.removeItem`)"
        :text="$t(`salesProductList.removeItemText`)"
        cancel-button="No, keep it"
        ok-button="Yes, remove"
        v-on:success="cancelReturn"
      />
      <ConfirmModal
        ref="confirmPackingModal"
        :title="$t(`salesProductList.noBoxError`)"
        :text="$t(`salesProductList.noBoxErrorText`)"
        cancel-button="No, cancel"
        ok-button="Yes, create"
        v-on:success="generateShipping(packingOrder, true)"
      />
    </div>
  </div>
</template>
<script>
import OneByOneTable from "../../components/lists/OneByOneTable";
import http from "@/modules/http";
import { defineAsyncComponent } from "vue";
import BaseIcon from "@/components/icons/BaseIcon";
import ScanModal from "@/components/modals/ScanModal";
import ws from "@/modules/ws";
import { useStore } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal";
import printJS from "print-js";
import store from "@/store";
import deliveredStatuses from "@/modules/DeliveredStatuses";
import tenant from "../../modules/tenant";
import { Vue3Lottie } from "vue3-lottie";
import emptyJSON from "../../assets/animations/ghost.json";

export default {
  name: "SalesProductList",
  components: { Vue3Lottie, ConfirmModal, ScanModal, BaseIcon, OneByOneTable },
  data() {
    return {
      store: useStore(),
      deliveredStatuses: deliveredStatuses,
      emptyJSON: emptyJSON,
      packingOrder: null,
      rows: [
        {
          name: "Trolley bin code",
          key: "bin_code",
          type: "text",
          show: true,
          editable: false,
        },
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Left",
          key: "need_to_sent_quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Price",
          key: "payed_price",
          type: "price",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
      ],
      orders: undefined,
      itemsById: {},
      ordersById: {},
      cancelItem: {},
      packagingInfo: {
        icon: null,
        status: "",
        text: "",
        selectedItems: [],
        selectItemMethod: null,
      },
    };
  },
  computed: {
    filteredOrders() {
      if (this.q === null || this.q.length < 2) {
        return this.orders;
      }
      let spacePos = this.q.indexOf(" ");
      if (spacePos < 2) {
        spacePos = this.q.length;
      }
      return this.orders.filter(
        (e) =>
          e.orderItems.findIndex(
            (g) =>
              g.sku?.indexOf(this.q) === 0 ||
              g.ean?.indexOf(this.q) === 0 ||
              g.upc?.indexOf(this.q) === 0 ||
              (typeof g.bin_code === "string" &&
                g.bin_code?.indexOf(this.q) === 0)
          ) > -1 ||
          (e?.public_tracking_code?.indexOf(
            this.q.substr(2, this.q.length - 4)
          ) > -1 &&
            e?.public_tracking_code?.indexOf(
              this.q.substr(2, this.q.length - 4)
            ) < 6) ||
          e?.pretty_id?.indexOf(this.q) > -1 ||
          (e?.first_name?.indexOf(this.q.substr(0, spacePos)) > -1 &&
            e?.last_name?.indexOf(this.q.substr(spacePos, 100)) > -1) ||
          (e?.last_name?.indexOf(this.q.substr(0, spacePos)) > -1 &&
            e?.first_name?.indexOf(this.q.substr(spacePos, 100)) > -1)
      );
    },
    type() {
      return this.$router.currentRoute.value.meta?.type;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    q() {
      return this.store.state.topBar.q;
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  watch: {
    warehouse() {
      this.loadData();
    },
    type() {
      this.loadData();
    },
    q(newValue, oldValue) {
      if (newValue?.length < oldValue?.length) {
        return;
      }
      if (this.filteredOrders?.length === 1) {
        let oid = this.filteredOrders[0].order_package_id
          ? "serach_box_" +
            this.filteredOrders[0].id +
            "_" +
            this.filteredOrders[0].order_package_id
          : "serach_box_" + this.filteredOrders[0].id;

        setTimeout(() => this.$refs[oid][0].focus(), 300);
      }
    },
  },
  mounted() {
    ws.init();

    ws.subscribe("modify", "orderItem", (e) => {
      if (
        e.rowKey === "collected" ||
        e.rowKey === "in_package" ||
        e.rowKey === "quantity" ||
        e.rowKey === "returned" ||
        e.rowKey === "need_to_sent_quantity"
      ) {
        let obj = this.itemsById[e.objectId];
        if (obj !== undefined) {
          obj[e.rowKey] = e.value;
        }
      }
    });

    this.loadData();
    document.getElementById("search-bar").focus();
    document.getElementById("search-bar").select();
  },
  methods: {
    loadData() {
      if (this.type === "return") {
        let tmpi = this.rows.findIndex((e) => e.key === "returned");
        if (tmpi < 0) {
          this.rows.push({
            name: "Returned",
            key: "returned",
            type: "boolean",
            show: true,
            editable: false,
            center: true,
          });
        }
        tmpi = this.rows.findIndex((e) => e.key === "collected");
        if (tmpi > -1) {
          this.rows.splice(tmpi, 1);
        }
        tmpi = this.rows.findIndex((e) => e.key === "in_package");
        if (tmpi > -1) {
          this.rows.splice(tmpi, 1);
        }
      } else {
        let tmpi = this.rows.findIndex((e) => e.key === "collected");
        if (tmpi < 0) {
          this.rows.push({
            name: "Collected",
            key: "collected",
            type: "boolean",
            show: true,
            editable: false,
            center: true,
          });
        }
        tmpi = this.rows.findIndex((e) => e.key === "in_package");
        if (tmpi < 0) {
          this.rows.push({
            name: "In package",
            key: "in_package",
            type: "boolean",
            show: true,
            editable: false,
            center: true,
          });
        }
        tmpi = this.rows.findIndex((e) => e.key === "returned");
        if (tmpi > -1) {
          this.rows.splice(tmpi, 1);
        }
      }

      this.orders = undefined;
      if (this.warehouse === undefined || this.warehouse === null) {
        return;
      }
      http
        .fetch(
          `/orders/${
            this.type === "return" ? "returns" : "products"
          }?warehouse=${this.warehouse}`
        )
        .then((data) => {
          this.orders = Object.values(data.data);
          this.ordersById = {};
          for (const key in this.orders) {
            this.orders[key].packages =
              this.selectedWarehouse.packing_start_boxes;
            this.ordersById[
              this.orders[key].order_package_id
                ? this.orders[key].id + "_" + this.orders[key].order_package_id
                : this.orders[key].id
            ] = this.orders[key];
            for (const itemKey in this.ordersById[
              this.orders[key].order_package_id
                ? this.orders[key].id + "_" + this.orders[key].order_package_id
                : this.orders[key].id
            ].orderItems) {
              let item = this.orders[key].orderItems[itemKey];
              this.itemsById[this.orders[key].orderItems[itemKey].id] = item;

              if (item.image !== null && item.image !== undefined) {
                item.image =
                  item.image.indexOf("http") == 0
                    ? item.image
                    : this.mediaServer + "/" + item.image;
              }
            }
          }
        });
    },
    setCollected(order) {
      order.loadCollectBtn = true;
      let oid = order.order_package_id
        ? order.id + "_" + order.order_package_id
        : order.id;
      let i = 0;
      for (const element of this.ordersById[oid].orderItems) {
        if (!element.in_package) {
          for (let e = 0; e < element.need_to_sent_quantity; e++) {
            setTimeout(
              () => this.packaging(order.id, element.id, oid),
              i * 300
            );
            i++;
          }
        }
      }
      setTimeout(() => (order.loadCollectBtn = false), i * 300);
    },
    generateShipping(order, force = false) {
      if (
        !force &&
        this.selectedWarehouse.notify_for_no_box === true &&
        (order.extraItems === null ||
          order.extraItems === undefined ||
          order.extraItems.length < 1)
      ) {
        this.packingOrder = order;
        this.$refs.confirmPackingModal.showModal();
        return;
      }

      order.loadShipBtn = true;
      http
        .fetch(
          "/orders/delivery",
          {
            order_package_id: order.order_package_id,
            packages: order.packages,
          },
          true
        )
        .then((data) => {
          order.tracking_code = data.tracking_code;
          order.delivery_method_id = data.delivery_method_id;
          order.loadShipBtn = false;
          this.printLabel(order.tracking_code, order.delivery_method_id);
        })
        .catch(() => {
          order.loadShipBtn = false;
        });
    },
    printLabel(code, method) {
      console.log("initialize printing");
      const url = `${tenant.getApiBaseUrl()}/deliveries/label/${method}?code=${code}&_token=${
        store.state.token
      }`;
      printJS({
        printable: url,
        type: "pdf",
        onLoadingStart: () => {
          console.log("printer loading..");
        },
        onLoadingEnd: () => {
          console.log("start printing");
          document.getElementById("search-bar").focus();
          document.getElementById("search-bar").select();
        },
        onError: (err) => {
          window.open(url, "_blank");
          console.log("printer error:");
          console.log(err);
        },
      });
    },
    cancelReturnModal(order, item) {
      if (this.type !== "return") {
        return;
      }
      this.cancelItem = {
        order: order,
        item: item,
      };
      this.$refs.confirmModal.showModal();
    },
    cancelReturn() {
      if (this.type !== "return") {
        return;
      }
      http
        .fetch(`/orders/${this.cancelItem.order.id}/cancel-return`, {
          item: this.cancelItem.item.id,
        })
        .then(() => {
          let i = this.ordersById[
            this.cancelItem.order.order_package_id
              ? this.cancelItem.order.id +
                "_" +
                this.cancelItem.order.order_package_id
              : this.cancelItem.order.id
          ].orderItems.indexOf(this.cancelItem.item);
          if (i > -1) {
            this.ordersById[
              this.cancelItem.order.order_package_id
                ? this.cancelItem.order.id +
                  "_" +
                  this.cancelItem.order.order_package_id
                : this.cancelItem.order.id
            ].orderItems.splice(i, 1);
          }
        });
    },
    addToPackage(order, barcode, package_id = null) {
      let oid = package_id ? order + "_" + package_id : order;

      if (barcode === null && barcode.length < 3) {
        return;
      }

      this.packagingInfo.icon = 3;
      this.packagingInfo.status = "Searching";
      this.packagingInfo.text = "just a few moments..";
      //this.$refs.packagingModal.showModal();

      this.ordersById[oid].barcode = "";
      this.ordersById[oid].disable = true;

      let elements = this.ordersById[oid].orderItems.filter(
        (e) => e.sku === barcode || e.ean === barcode || e.upc === barcode
      );
      if (elements.length === 1) {
        this.packaging(order, elements[0].id, oid);
      } else if (elements.length > 1) {
        this.packagingInfo.status = "Multiple Barcode found";
        this.packagingInfo.text = "Select the correct product";
        this.packagingInfo.selectedItems = elements;
        this.packagingInfo.selectItemMethod = (p) => {
          this.packaging(order, p.id, oid);
        };
        this.$refs.packagingModal.showModal();
      } else {
        http
          .fetch("/products/materials/search", { q: barcode })
          .then((data) => {
            if (data.length > 1) {
              this.packagingInfo.status = "Multiple Barcode found";
              this.packagingInfo.text = "Select the correct product";
              this.packagingInfo.selectedItems = data;
              this.packagingInfo.selectItemMethod = (p) => {
                this.packaging(package_id, p.variant_id, oid, true, p);
              };
              this.$refs.packagingModal.showModal();
            } else if (data.length === 1) {
              this.packaging(
                package_id,
                data[0].variant_id,
                oid,
                true,
                data[0]
              );
            } else {
              this.packagingInfo.icon = 2;
              this.packagingInfo.status = "Barcode not found";
              this.packagingInfo.text =
                "We cannot find any item with this barcode in this transfer.";
              this.$refs.packagingModal.showModal();
              this.ordersById[oid].disable = false;
            }
          });
      }
    },
    packaging(order, item, oid, extra = false, itemData = null) {
      http
        .fetch(
          `/orders/${order}/${
            extra
              ? "packaging-extra"
              : this.type === "return"
              ? "returned"
              : "packaging"
          }`,
          { item: item }
        )
        .then((data) => {
          if (extra) {
            if (this.ordersById[oid].extraItems === undefined) {
              this.ordersById[oid].extraItems = [];
            }
            this.ordersById[oid].extraItems.push(itemData);
            //this.ordersById[oid].packages += 1;
          } else {
            let e = this.itemsById[item];
            if (e === undefined) {
              return;
            }
            e.need_to_sent_quantity = data.statuses?.need_to_sent_quantity;

            if (data.statuses?.need_to_sent_quantity === 0) {
              e.order_package_id = true;
              e.in_package = true;
            }

            if (data.statuses?.need_to_sent_quantity === null) {
              e.returned = true;
            }
          }

          this.packagingInfo.status =
            this.type === "return"
              ? "The item received in the warehouse"
              : "Item added to the package";
          this.packagingInfo.text =
            this.type === "return"
              ? "The item received in the warehouse"
              : "Item added to the package";
          this.packagingInfo.icon = 1;
          this.ordersById[oid].show_success = true;
          this.ordersById[oid].disable = false;
          setTimeout(() => {
            this.$refs["serach_box_" + oid][0].focus();
          }, 40);
          setTimeout(() => {
            this.ordersById[oid].show_success = false;
          }, 600);
          // this.$refs.packagingModal.showModal();
        })
        .catch((data) => {
          this.ordersById[oid].disable = false;
          this.packagingInfo.icon = 2;
          this.packagingInfo.status = "Error";
          this.packagingInfo.text = data.message;
          this.$refs.packagingModal.showModal();
        });
    },
  },
};
</script>
